.tray-layout table {
    width: 100%;
    border: 1px solid #29335c;
}

.tray-layout th, .tray-layout td {
    text-align: center;
    vertical-align: middle;
    border: 1px solid #29335c;
    border-collapse: collapse;
    padding: 0.3em;
}
