.GeneralForm .form-header {
    margin-top: 20px;
}

.GeneralForm .section-header {
    margin-top: 20px;
    margin-bottom: 10px;
    color: white;
    background-color: #29335c;
}

/*.GeneralForm .card-header-custom {*/
/*    margin-bottom: 10px;*/
/*    color: white;*/
/*    background-color: #29335c;*/
/*}*/

.card-header {
    color: white !important;
    background-color: #29335c !important;
}


.GeneralForm label {
    color: #40496b;
}

.general-form-buttons {
    margin-top: 30px;
    margin-bottom: 30px;
}


.invalid-input {
    color: red;
    border: 1px solid red;
}