body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.bg-company {
  background-color: #f64d52;
}
@media (min-width: 576px) {
  .wide-container {
    width: 540px;
  }
}

@media (min-width: 768px) {
  .wide-container {
    width: 720px;
  }
}

@media (min-width: 992px) {
  .wide-container {
    width: 960px;
  }
}

@media (min-width: 1200px) {
  .wide-container {
    width: 1140px;
  }
}

@media (min-width: 1400px) {
  .wide-container {
    width: 1260px;
  }
}

@media (min-width: 1600px) {
  .wide-container {
    width: 1400px;
  }
}

@media (min-width: 1800px) {
  .wide-container {
    width: 1600px;
  }
}

.wide-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;
}

.btn-outline-primary {
  color: #29335c;
  border-color: #29335c;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #29335c;
  border-color: #29335c;
}
.btn-outline-primary:not(:disabled):hover {
  color: #fff;
  background-color: #29335c;
  border-color: #29335c;
}
.btn-outline-danger {
  color: #ea105e;
  border-color: #ea105e;
}
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #ea105e;
  border-color: #ea105e;
}
.btn-outline-danger:not(:disabled):hover {
  color: #fff;
  background-color: #ea105e;
  border-color: #ea105e;
}
.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):hover {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-success {
  color: #00d570;
  border-color: #00d570;
}
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #00d570;
  border-color: #00d570;
}
.btn-outline-success:not(:disabled):hover {
  color: #fff;
  background-color: #00d570;
  border-color: #00d570;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #29335c;
}

.positive {
  color: #fff;
  background-color: #ea105e;
  border-color: #ea105e;
  padding: 5px;
}
.negative {
  color: #fff;
  background-color: #00d570;
  border-color: #00d570;
  padding: 5px;
}
.warning {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
  padding: 5px;
}

.dropdown-menu {
  position: absolute;
  overflow: auto;
  right: 0 !important;
  left: 0 !important;
}

tr.active {
  background-color: lightyellow;
}

#__react-alert__ div div div {
  width: auto !important;
  -webkit-box-flex: 3;
  -webkit-flex: 3;
  -ms-flex: 3;
  flex: 3;
  text-transform: initial !important;
  font: inherit !important;
  padding: 24px 10px;
  z-index: 1000000 !important;
}

#__react-alert__ div {
  z-index: 1000000 !important;
}

.validation-error {
  font-size: small;
  font-style: italic;
  color: red;
}

.float-right {
  float: right;
}

.action-btn {
  padding: 5px;
}

.fileName {
  width: 700px !important;
}

.no-scroll {
  overflow: hidden; /* Prevent scrolling */
}
.cursor-pointer {
  cursor: pointer;
}

input[type="number"]::-webkit-outer-spin-button, /*remove the up/down arrows (spinner) input*/
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  /*remove the up/down arrows (spinner) input*/
  -moz-appearance: textfield;
}
